var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fb-ligitant" },
        _vm._l(_vm.ligitantList, function (ligitant, ligitantIndex) {
          return _c(
            "div",
            { key: ligitantIndex, staticClass: "ligitant-info" },
            [
              _c("p", { staticClass: "entrance-item" }, [
                _c("label", { staticClass: "label-item" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("label", { staticClass: "label-value" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: ligitant.name,
                        expression: "ligitant.name",
                      },
                    ],
                    staticClass: "input-value",
                    attrs: { type: "text" },
                    domProps: { value: ligitant.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(ligitant, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: ligitant.isShowLigitantInfo,
                      expression: "ligitant.isShowLigitantInfo",
                    },
                  ],
                  staticClass: "ligitant-key-value",
                },
                [
                  _c("label", { staticClass: "label-key" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          ligitant.category === "NATURAL_PERSON"
                            ? "身份证号"
                            : "统一社会信用代码"
                        ) +
                        " "
                    ),
                  ]),
                  _c("label", { staticClass: "label-value" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: ligitant.idNumber,
                          expression: "ligitant.idNumber",
                        },
                      ],
                      staticClass: "input-value-short-width",
                      attrs: { type: "text" },
                      domProps: { value: ligitant.idNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(ligitant, "idNumber", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._l(ligitant, function (ligitantInfoValue, ligitantInfoKey) {
                return _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.ligitantType[ligitant.category].infos.includes(
                            ligitantInfoKey
                          ),
                        expression:
                          "ligitantType[ligitant.category].infos.includes(ligitantInfoKey)",
                      },
                    ],
                    key: ligitantInfoKey,
                    staticClass: "ligitant-key-value",
                  },
                  [
                    _c("label", { staticClass: "label-key" }, [
                      _vm._v(
                        _vm._s(_vm.ligitantInfoKeyChineseMap[ligitantInfoKey])
                      ),
                    ]),
                    _c("label", { staticClass: "label-value" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ligitant[ligitantInfoKey],
                            expression: "ligitant[ligitantInfoKey]",
                          },
                        ],
                        staticClass: "input-value",
                        attrs: { type: "text" },
                        domProps: { value: ligitant[ligitantInfoKey] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              ligitant,
                              ligitantInfoKey,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]
                )
              }),
              ligitant.category !== "NATURAL_PERSON"
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: ligitant.isShowLigitantInfo,
                          expression: "ligitant.isShowLigitantInfo",
                        },
                      ],
                      staticClass: "ligitant-key-value",
                    },
                    [
                      _c("label", { staticClass: "label-key" }, [
                        _vm._v("人物身份"),
                      ]),
                      _c("label", { staticClass: "label-value" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              ligitant.category === "LEGAL_ENTITY"
                                ? "法定代表人"
                                : "主要负责人"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._l(ligitant.agentList, function (agent, agentIndex) {
                return _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: ligitant.isShowLigitantInfo,
                        expression: "ligitant.isShowLigitantInfo",
                      },
                    ],
                    key: agentIndex,
                    staticClass: "ligitant-key-value",
                  },
                  [
                    _c("label", { staticClass: "label-key" }, [
                      _vm._v("代理人"),
                    ]),
                    _c("label", { staticClass: "label-value" }, [
                      _c("label", { staticClass: "input-value" }, [
                        _vm._v(_vm._s(agent.name)),
                      ]),
                    ]),
                  ]
                )
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: ligitant.isShowLigitantInfo,
                      expression: "ligitant.isShowLigitantInfo",
                    },
                  ],
                  staticClass: "ligitant-operation",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "delete-ligitant",
                      on: {
                        click: function ($event) {
                          return _vm.deleteLigitant(ligitant, ligitantIndex)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "save-ligitant",
                      on: {
                        click: function ($event) {
                          return _vm.saveLigitant(ligitant)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ]
              ),
              _c("div", { staticClass: "clear" }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }