<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <!-- <fb-button slot="right" icon="menu" size="small" type="primary">
      </fb-button> -->
    </fb-header>
    <div class="fb-ligitant">
      <div class="ligitant-info"
           v-for="(ligitant, ligitantIndex) in ligitantList"
           :key="ligitantIndex">
        <!-- <p class="entrance-item" @click="toggleLigitantInfo(ligitantList, ligitant, ligitantIndex)"> -->
        <p class="entrance-item">
          <label class="label-item">{{ title }}</label>
          <label class="label-value">
            <input type="text"
                   class="input-value"
                   v-model="ligitant.name">
            <!-- <i class="iconfont" :class="ligitant.arrowDirection"></i> -->
          </label>
        </p>
        <p class="ligitant-key-value"
           v-show="ligitant.isShowLigitantInfo">
          <label class="label-key">
            <!-- 根据是否为自然人确定idNumber对应的名称 -->
            {{ ligitant.category === 'NATURAL_PERSON'? '身份证号': '统一社会信用代码' }}
          </label>
          <label class="label-value">
            <input type="text"
                   class="input-value-short-width"
                   v-model="ligitant.idNumber">
          </label>
        </p>
        <p class="ligitant-key-value"
           v-for="(ligitantInfoValue, ligitantInfoKey) in ligitant"
           :key="ligitantInfoKey"
           v-show="ligitantType[ligitant.category].infos.includes(ligitantInfoKey)">
          <label class="label-key">{{ ligitantInfoKeyChineseMap[ligitantInfoKey] }}</label>
          <label class="label-value">
            <input type="text"
                   class="input-value"
                   v-model="ligitant[ligitantInfoKey]">
          </label>
        </p>
        <p class="ligitant-key-value"
           v-show="ligitant.isShowLigitantInfo"
           v-if="ligitant.category !== 'NATURAL_PERSON'">
          <label class="label-key">人物身份</label>
          <label class="label-value">
            <!-- 根据是否为法人机构确定对应的人物身份 -->
            {{ ligitant.category === 'LEGAL_ENTITY'? '法定代表人': '主要负责人' }}
          </label>
        </p>
        <p class="ligitant-key-value"
           v-show="ligitant.isShowLigitantInfo"
           :key="agentIndex"
           v-for="(agent, agentIndex) in ligitant.agentList">
          <label class="label-key">代理人</label>
          <label class="label-value">
            <label class="input-value">{{ agent.name }}</label>
            <!-- <i class="iconfont icon-child-off"></i> -->
          </label>
        </p>
        <div class="ligitant-operation"
             v-show="ligitant.isShowLigitantInfo">
          <div class="delete-ligitant"
               @click="deleteLigitant(ligitant, ligitantIndex)">删除</div>
          <div class="save-ligitant"
               @click="saveLigitant(ligitant)">保存</div>
        </div>
        <div class="clear"></div>
      </div>
      <!-- <p class="add-ligitant" @click="addLigitant()">
        <label class="add-label">添加{{ title }}</label>
        <i class="iconfont icon-tianjia1"></i>
      </p> -->
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
// import Qs from 'qs'
export default {
  data () {
    return {
      title: '',
      ligitantList: [],
      ligitantName: '',
      caseId: 0,
      ligitantInfoKeyChineseMap: {
        sex: '性别',
        address: '地址',
        phone: '电话',
        nation: '民族',
        position: '职位',
        manager: '姓名'
      },
      ligitantType: {
        NATURAL_PERSON: {
          chinese: '自然人',
          infos: ['phone', 'sex', 'nation', 'address']
        },
        LEGAL_ENTITY: {
          chinese: '法人单位',
          infos: ['position', 'manager', 'phone', 'address']
        },
        NON_LEGAL_ENTITY: {
          chinese: '非法人单位',
          infos: ['position', 'manager', 'phone', 'address']
        }
      }
    }
  },
  created () {
    this.title = this.$route.params.ligitantType
    this.caseId = this.$route.params.caseId
    const ligitantInfoIndex = this.$route.params.ligitantInfoIndex
    const ligitantList = [
      {
        ...this.$route.params.ligitant[0],
        sex:
          this.$route.params.ligitant[0].sex === 'MALE'
            ? '男'
            : this.$route.params.ligitant[0].sex === 'FEMALE'
              ? '女'
              : '未知'
      }
    ]
    ligitantList.forEach((element, index) => {
      if (index === ligitantInfoIndex) {
        element.isShowLigitantInfo = true
        element.arrowDirection = 'icon-child-on'
      } else {
        element.isShowLigitantInfo = false
        element.arrowDirection = 'icon-child-off'
      }
    })
    this.ligitantList = ligitantList
    console.log('ligitantList', this.ligitantList)
  },
  methods: {
    toggleLigitantInfo (ligitantList, ligitant, ligitantIndex) {
      ligitant.isShowLigitantInfo = !ligitant.isShowLigitantInfo
      ligitant.arrowDirection = ligitant.isShowLigitantInfo
        ? 'icon-child-on'
        : 'icon-child-off'
      ligitantList.splice(ligitantIndex, 1, ligitant)
    },
    deleteLigitant (ligitant, ligitantIndex) {
      console.log('DELETE', ligitant)
      this.$axios
        .delete(caseBase + '/lts/caseLitigant/deleteLitigant', {
          params: {
            caseId: this.caseId,
            litigantId: ligitant.litigantId
          }
        })
        .then(res => {
          if (Object.is('200', res.data.code)) {
            this.ligitantList.splice(ligitantIndex, 1)
            this.$router.back()
          }
        })
    },
    saveLigitant (ligitant) {
      console.log('SAVE', ligitant)
      // todo 下面这行代码为了测试接口，正式环境需要删除
      // this.caseId = 82
      ligitant = {
        ...ligitant,
        sex:
          ligitant.sex === '男'
            ? 'MALE'
            : ligitant.sex === '女'
              ? 'FEMALE'
              : 'UNKNOWN'
      }
      console.log(ligitant)
      this.$axios
        .put(
          caseBase + `/lts/litigant/updateLitigant?caseId=${this.caseId}`,
          ligitant
        )
        .then(res => {
          if (res.data.code === '200') {
            this.$router.back()
          }
          console.log('saveLigitant', res.data)
        })
    },
    addLigitant () {
      console.log('ADD')
    },
    showAgentInfo (agent) {
      console.log('agent', agent)
      this.$router.push({ name: 'agent' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.fb-ligitant
  text-align center
  width 90%
  margin 10px auto
  background-color #EEF7FC
  .ligitant-info, .add-ligitant
    border-radius 5px
    background white
    font-size 15px
    box-shadow 0px 0px 0px 1px #DCF0FA
    margin-bottom 10px
    .entrance-item, .ligitant-key-value
      overflow auto
      // height 40px
      .label-item, .label-key
        padding-left 10px
        float left
        line-height 40px
        color #169BD5
      .label-value
        float right
        line-height 40px
        padding-right 10px
        .input-value, .input-value-short-width
          margin-top -5px
          height 40px
          text-align right
          line-height 40px
          border none
        .input-value
          width 240px
        .input-value-short-width
          width 150px
        .iconfont
          font-size 20px
          color #169BD5
    .ligitant-key-value
      border-top 1px solid #80808024
      .label-key
        color black
    .ligitant-operation
      overflow hidden
      height 60px
      .delete-ligitant, .save-ligitant
        background-color #26a2ff
        border-radius 5px
        width 100px
        height 30px
        color white
        line-height 30px
        margin-top 10px
      .delete-ligitant
        float left
        margin-left 20px
      .save-ligitant
        float right
        margin-right 20px
    .clear
      clear both
  .add-ligitant
    height 40px
    .add-label
      float left
      margin-left 10px
      line-height 40px
      color #169BD5
    .iconfont
      float right
      margin-right 10px
      line-height 40px
      color #169BD5
</style>
